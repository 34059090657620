/**
 * The ISYL environment of the app
 */
export const environment = {
  production: true,
  title: 'Snag-list [D]',
  color: '#000080',
  settings: {
    forceLogin: false,
    account: {
      hasCreateFunctionality: true,
      hasReadFunctionality: true,
      hasUpdateFunctionality: true,
      hasDeleteFunctionality: true,
    },
    project: {
      hasCreateFunctionality: true,
      hasReadFunctionality: true,
      hasUpdateFunctionality: true,
      hasDeleteFunctionality: true,
    },
    shipyard: {
      hasCreateFunctionality: true,
      hasReadFunctionality: true,
      hasUpdateFunctionality: true,
      hasDeleteFunctionality: true,
    },
    boat: {
      hasCreateFunctionality: true,
      hasReadFunctionality: true,
      hasUpdateFunctionality: true,
      hasDeleteFunctionality: true,
    },
    section: {
      asDecks: true,
      hasCreateFunctionality: true,
      hasReadFunctionality: true,
      hasUpdateFunctionality: true,
      hasDeleteFunctionality: true,
    },
    task: {
      filterStatuses: [
        {
          status_id: 'submitted',
          name: 'Sent'
        },
        // {
        //   status_id: 'denied',
        //   name: 'Declined'
        // },
        // {
        //   status_id: 'accepted',
        //   name: 'Accepted'
        // },
        {
          status_id: 'in progress',
          name: 'In progress'
        },
        {
          status_id: 'completed',
          name: 'Completed'
        },
        // {
        //   status_id: 'monitored',
        //   name: 'Monitored'
        // }
      ],
      flowStatuses: [
        // Primary
        {
          key: 'draft',
          label: 'Draft',
          nextKeys: ['draft', 'submitted'],
          previousKeys: []
        },
        {
          key: 'submitted',
          label: 'Sent',
          nextKeys: ['submitted', 'accepted', 'denied'],
          previousKeys: ['draft']
        },
        {
          key: 'accepted',
          label: 'Accepted',
          nextKeys: ['accepted', 'in progress'],
          previousKeys: ['submitted']
        },
        {
          key: 'denied',
          label: 'Declined',
          nextKeys: ['denied'],
          previousKeys: ['submitted']
        },
        {
          key: 'in progress',
          label: 'In progress',
          // nextKeys: ['in progress', 'completed'],
          nextKeys: ['in progress', 'denied', 'completed'],
          previousKeys: ['accepted', 'monitored']
        },
        {
          key: 'completed',
          label: 'Completed',
          nextKeys: ['completed', 'in progress', 'monitored'],
          previousKeys: ['in progress']
        },
        {
          key: 'monitored',
          label: 'Monitored',
          nextKeys: ['monitored', 'in progress'],
          previousKeys: ['completed']
        },
        // Remark
        {
          key: 'open',
          label: 'Open',
          nextKeys: ['open', 'no_actions', 'local_actions', 'total_actions'],
          previousKeys: []
        },
        {
          key: 'no_actions',
          label: 'No actions',
          nextKeys: ['no_actions', 'closed-no_actions'],
          previousKeys: []
        },
        {
          key: 'closed-no_actions',
          label: 'Closed remark',
          nextKeys: [],
          previousKeys: []
        },
        {
          key: 'local_actions',
          label: 'Local actions',
          nextKeys: ['local_actions', 'closed-local_actions'],
          previousKeys: []
        },
        {
          key: 'closed-local_actions',
          label: 'Closed remark',
          nextKeys: [],
          previousKeys: []
        },
        {
          key: 'total_actions',
          label: 'Total actions',
          nextKeys: ['total_actions', 'closed-total_actions'],
          previousKeys: []
        },
        {
          key: 'closed-total_actions',
          label: 'Closed remark',
          nextKeys: [],
          previousKeys: []
        },
      ],
      hasCreateFunctionality: true,
      hasOfflineDraftsFuncionality: false,
      hasPreviuosStatusFuncionality: false,
      create: {
        useSuggestions: true,
        canChangeStatus: true,
        showZone: true,
        canSetZone: false,
        canChangeZone: true,
        canChangeType: true,
        canViewDoc: false,
        showPrivacy: false,
        canChangePrivacy: true,
        canChangeLocation: false,
        showResolutionDate: false,
        canSetResolutionDate: false,
        canChangeResolutionDate: false,
        showResolutor: false,
        canSetResolutor: false,
        canChangeResolutor: false,
        showDocumentReference: false,
        canSetDocumentReference: false,
        canChangeDocumentReference: false
      },
      edit: {
        useSuggestions: true,
        canChangeStatus: true,
        showZone: true,
        canSetZone: true,
        canChangeZone: false,
        canChangeType: false,
        canViewDoc: true,
        showPrivacy: false,
        canChangePrivacy: true,
        canChangeLocation: false,
        canBeDeleted: true,
        showResolutionDate: false,
        canSetResolutionDate: false,
        canChangeResolutionDate: false,
        showResolutor: false,
        canSetResolutor: false,
        canChangeResolutor: false,
        showDocumentReference: false,
        canSetDocumentReference: false,
        canChangeDocumentReference: false
      }
    },
    report: {
      listOfPoints: {
        csvType: 'base'
      }
    },
    hasUpdatesFunctionality: false,
    hasYachtFilterFunctionality: true,
    hasYachtHistoryFunctionality: false,
    hasYachtInfoFunctionality: true,
    hasAccountsFunctionality: false,
    hasPointFunctionality: true,
    hasEnvironmentFunctionality: true,
    hasZoneFunctionality: true,
    hasRemarkFunctionality: true,
    hasProductFunctionality: true,
    hasApplicationFunctionality: true, // Require zone, remark, product, ...
    hasManagementFunctionality: false,
    hasSurfaceFunctionality: false,
  },
  storage: {
    prefix: 'ISYL_SNAGLIST_D__'
  },
  api: {
    client_id: 2,
    client_secret: 'jzgo82ocJhlEoqG58e8FzzdGcEmJ9MWqOmBAnoSk',
    oauth_base_url: 'https://isyl-d.production.isyl.it/oauth/',
    auth_base_url: 'https://isyl-d.production.isyl.it/api/auth/',
    base_url: 'https://isyl-d.production.isyl.it/api/',
    domain: 'https://isyl-d.production.isyl.it/',
    version: 'v1'
  },
  drive: {
    clientId: '',
    apiKey: '',
    appId: '',
    scope: 'https://www.googleapis.com/auth/drive.file',
    parentFolder: 'root'
  }
};
